import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IconContext } from "react-icons";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsGoogle } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";

import './Footer.css';

export default function Footer() {
    return (
        <Container fluid className='footer-container'>
          <Row className="justify-content-center py-2 mb-3">
            <Col xs={1} className="px-4">
                <IconContext.Provider value={{ color: 'white'}}>
                    <a href="#!" role="button"><BsFacebook className='footer-icon'/></a>
                </IconContext.Provider>
            </Col>
            <Col xs={1} className="px-4">
                <IconContext.Provider value={{ color: 'white'}}>
                    <a href="#!" role="button"><BsTwitter className='footer-icon'/></a>
                </IconContext.Provider>
            </Col>
            <Col xs={1} className="px-4">
                <IconContext.Provider value={{ color: 'white'}}>
                    <a href="#!" role="button"><BsGoogle className='footer-icon'/></a>
                </IconContext.Provider>
            </Col>
            <Col xs={1} className="px-4">
                <IconContext.Provider value={{ color: 'white'}}>
                    <a href="#!" role="button"><BsInstagram className='footer-icon'/></a>
                </IconContext.Provider>
            </Col>
            <Col xs={1} className="px-4">
                <IconContext.Provider value={{ color: 'white'}}>
                    <a href="#!" role="button"><BsLinkedin className='footer-icon'/></a>
                </IconContext.Provider>
            </Col>
          </Row>
          <Row>
            <p className='footer-text'>© 2023 HOME CONTROL DEL SURESTE S.A. de C.V.</p>
          </Row>
        </Container>
    );
  }