import {useInView} from 'react-intersection-observer'

import { Link} from "react-router-dom"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Particles from '../../components/Particles';
import ImageSlider from '../../components/Carousel/Carousel';

import './Inicio.css';
import '../../components/animations.css'

export function Inicio() {
    const proyectos = [
        { url: "../img/pr-1.jpeg", title: "p-1" },
        { url: "../img/pr-2.jpeg", title: "p-2" },
        { url: "../img/pr-3.jpeg", title: "p-3" },
    ]

    const ejecucion = [
        { url: "../img/ej-1.jpeg", title: "e-1" },
        { url: "../img/ej-2.jpeg", title: "e-2" },
        { url: "../img/ej-3.jpeg", title: "e-3" },
    ]

    const listo = [
        { url: "../img/li-1.jpeg", title: "l-1" },
        { url: "../img/li-2.jpeg", title: "l-2" },
        { url: "../img/li-3.jpeg", title: "l-3" },
    ]
    
    const { ref: logo1, inView: IsVisible1} = useInView();
    const { ref: logo2, inView: IsVisible2} = useInView();
    const { ref: logo3, inView: IsVisible3} = useInView();
    const { ref: logo4, inView: IsVisible4} = useInView();

    const { ref: txt1, inView: IsVisible8} = useInView();
    const { ref: txt2, inView: IsVisible9} = useInView();
    const { ref: txt3, inView: IsVisible10} = useInView();

    const { ref: txt11, inView: IsVisible11} = useInView();
    const { ref: txt12, inView: IsVisible12} = useInView();

    const { ref: txt21, inView: IsVisible14} = useInView();
    const { ref: txt22, inView: IsVisible15} = useInView();
    const { ref: txt23, inView: IsVisible16} = useInView();

    const { ref: car1, inView: IsVisible17} = useInView();
    const { ref: car2, inView: IsVisible18} = useInView();
    const { ref: car3, inView: IsVisible19} = useInView();
    const { ref: car4, inView: IsVisible20} = useInView();
    const { ref: car5, inView: IsVisible21} = useInView();

    const { ref: car6, inView: IsVisible22} = useInView();
    const { ref: car7, inView: IsVisible23} = useInView();
    const { ref: car8, inView: IsVisible24} = useInView();
    const { ref: car9, inView: IsVisible25} = useInView();
    const { ref: car10, inView: IsVisible26} = useInView();

    return (
        <div className="inicio-container">
            
            <div className="navbar-img-container">
                <div className="diagonal-cover d-none d-lg-block">
                    <div className='text-container'>
                        <h1 className='img-title'>TU IDEA ES NUESTRO MEJOR PROYECTO.</h1>
                        <p className='img-text'>
                            Nuestros especialistas en integración trabajan con diversas ingenierias para hacer de tu proyecto tu mejor inversión.
                        </p>
                    </div>
                </div>
                <div className='logo-container'>
                    <img className='logo img-fluid' src='../img/logotipo-home.png' alt='logo'></img>
                </div>
                <Particles />
                <img className="navbar-img" img src="../img/placeholder.jpg" alt='place-holder-1'></img>
            </div>

            <Row className='justify-content-center center '>
                <h1 className='title-1'>Requerimientos distintos - Tecnología en común</h1>
            </Row>

            <Row className='justify-content-center my-5'>
                <Col sm={6} md={3} className='center'>
                    <img src='../img/1.png' alt='logo-1' className={`${'logo-img'} ${IsVisible1 ? 'fade-top-1' : ''}`} ref={logo1}></img>
                    <h2>Residencias</h2>
                </Col>
                <Col sm={6} md={3} className='center'>
                    <img src='../img/2.png' alt='logo-2' className={`${'logo-img'} ${IsVisible2 ? 'fade-top-2' : ''}`} ref={logo2}></img>
                    <h2>Condominios</h2>
                </Col>
                <Col sm={6} md={3} className='center'>
                    <img src='../img/3.png' alt='logo-3' className={`${'logo-img'} ${IsVisible3 ? 'fade-top-3' : ''}`} ref={logo3}></img>
                    <h2>Hoteles</h2>
                </Col>
                <Col sm={6} md={3} className='center'>
                    <img src='../img/4.png' alt='logo-4' className={`${'logo-img'} ${IsVisible4 ? 'fade-top-4' : ''}`} ref={logo4}></img>
                    <h2>Restaurantes</h2>
                </Col>
            </Row>

            <Row className='position-relative section-container'>
                <Col lg={6} xl={7} className='d-none d-lg-block'>
                    <ImageSlider slides={proyectos}  parentWidth={1500}/>
                </Col>
                <Col className='section-text-container-2'>
                    <h1 className={`${'title-2'} ${IsVisible8 ? 'fade-left' : ''}`} ref={txt1}>PRIMERO LO PRIMERO</h1>
                    <h1 className={`${'title-3'} ${IsVisible8 ? 'fade-left' : ''}`} ref={txt1}>Proyectos</h1>
                    <p className={`${'text-2'} ${IsVisible9 ? 'fade-left' : ''}`} ref={txt2}>
                        El diseño de sistemas especiales tiene su conceptualización como un todo, debidio a las dependencias de 
                        los sistemas con las redes, por ello contamos con un departamento dedicado a proyectos ejecutivos donde 
                        se aplica las mejoras práctricas de procedimientos constructivos y normas vigentes.
                    </p>
                    <Link className={`${''} ${IsVisible10 ? 'fade-left' : ''} a-btn`} ref={txt3} to="/Contactanos"><button className='contactanos-btn'>Contáctanos</button></Link>
                </Col>
                <div className="diagonal-cover-2"></div>
            </Row>

            <Row className='justify-content-around my-4 px-3'>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible17 ? 'fade-left-1' : ''}`} ref={car1}>
                    <img className='cr-img img-fluid' src='../img/cr-1.png' alt='cr-1'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible18 ? 'fade-left-2' : ''}`} ref={car2}>
                    <img className='cr-img img-fluid' src='../img/cr-2.png' alt='cr-2'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible19 ? 'fade-left-3' : ''}`} ref={car3}>
                    <img className='cr-img img-fluid' src='../img/cr-3.png' alt='cr-3'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible20 ? 'fade-left-4' : ''}`} ref={car4}>
                    <img className='cr-img-1 img-fluid' src='../img/cr-4.png' alt='cr-4'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible21 ? 'fade-left-5' : ''}`} ref={car5}>
                    <img className='cr-img img-fluid' src='../img/cr-5.png' alt='cr-5'></img>
                </Col>
            </Row>

            <Row className='position-relative section-container'>
                <div className="diagonal-cover-3"></div>
                <Col className='section-text-container'>
                    <h1 className={`${'title-2'} ${IsVisible11 ? 'fade-right' : ''}`} ref={txt11}>MANOS A LA OBRA</h1>
                    <h1 className={`${'title-3'} ${IsVisible11 ? 'fade-right' : ''}`} ref={txt11}>Ejecución</h1>
                    <p className={`${'text-2'} ${IsVisible12 ? 'fade-right' : ''}`} ref={txt12}>
                        <b>(Audio,Control de iluminación, Automatización, Integración)</b><br></br>
                        Elegir el mejor producto para cada caso es parte de nuestro departamento de proyectos; instalarlo, configurarlo, programarlo
                        es parte fundamental de la implementación de cada sistema, es por ello que al contratar los sistemas, nosotros nos encargaremos
                        de todo.
                    </p>
                    <p className={`${'text-2'} ${IsVisible12 ? 'fade-right' : ''}`} ref={txt12}>
                        <b>(Redes, Video vigilancia, Control de Acceso)</b><br></br>
                        Como parte secundaria, los sistemas base como redes y otro sistemas, tambien estarán no solo diseñados en el mismo proyecto,
                        sino tambien ejecutados a la par, esto garantizará la correcta integración de cada sistema.
                    </p>
                </Col>
                <Col lg={6} xl={6} xxl={7} className='d-none d-lg-block'>
                    <ImageSlider slides={ejecucion}  parentWidth={1500}/>
                </Col>
            </Row>

            <Row className='justify-content-around my-4 px-3'>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible22 ? 'fade-right-5' : ''}`} ref={car6}>
                    <img className='cr-img img-fluid' src='../img/cr-1.png' alt='cr-1'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible23 ? 'fade-right-4' : ''}`} ref={car7}>
                    <img className='cr-img img-fluid' src='../img/cr-2.png' alt='cr-2'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible24 ? 'fade-right-3' : ''}`} ref={car8}>
                    <img className='cr-img img-fluid' src='../img/cr-3.png' alt='cr-3'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible25 ? 'fade-right-2' : ''}`} ref={car9}>
                    <img className='cr-img-1 img-fluid' src='../img/cr-4.png' alt='cr-4'></img>
                </Col>
                <Col sm={2} className={`${'center cr-col'} ${IsVisible26 ? 'fade-right-1' : ''}`} ref={car10}>
                    <img className='cr-img img-fluid' src='../img/cr-5.png' alt='cr-5'></img>
                </Col>
            </Row>
            
            <Row className='position-relative section-container'>
                <Col lg={7} className='d-none d-lg-block'>
                    <ImageSlider slides={listo}  parentWidth={1500}/>
                </Col>
                <Col className='section-text-container-2'>
                    <h1 className={`${'title-2'} ${IsVisible14 ? 'fade-left' : ''}`} ref={txt21}> TODO LISTO</h1>
                    <h1 className={`${'title-2'} ${IsVisible14 ? 'fade-left' : ''}`} ref={txt21}> Post Venta</h1>
                    <p className={`${'text-2'} ${IsVisible15 ? 'fade-left' : ''}`} ref={txt22}>
                        La garantía de tener los sistemas funcionando, es parte de nuestro compromiso, por ello extendemos garantía de nuestros
                        trabajos por una año, ya sea en mano de obra o equipos, esto es posible ya que cada parte de los sistemas, desde su 
                        conceptualización, diseño, instalación y puesta en marchas fue realizado bajo una misma empresa, HOME CONTROL DEL SURESTE.
                    </p>
                    <Link className={`${''} ${IsVisible16 ? 'fade-left' : ''} a-btn`} ref={txt23} to="/Contactanos"><button className='contactanos-btn'>Contáctanos</button></Link>
                </Col>
                <div className="diagonal-cover-2"></div>
            </Row>

            <Row className='location-container'>
                <Col sm={12} md={5} className='contactanos-container'>
                    <h1 className='contact-title'>Contactanos</h1>
                    <p className='contact-text'>Dirección: Calle 22 num 254 x 27 y 29 colonia Monterreal, Mérida, Yucatán</p>
                    <p className='contact-text'>Correo: fpintoa@homecontrol.mx</p>
                    <p className='contact-text'>Telefono: 999-9262026</p>

                </Col>
                <Col sm={12} md={7} className='mapa-container'>
                    <a href="https://goo.gl/maps/su8JQwFPBNH5H6Gd9" target="_blank"><img src='../img/mapa.jpg' alt='map' className='mapa-img'></img></a>
                </Col>
            </Row>
            
        </div>
    );
  }