import {Route, Routes} from "react-router-dom"
import Footer from './pages/Footer/Footer'
import {Inicio} from "./pages/Inicio/Inicio"
import "./App.css"

function App() {
  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Inicio />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
  

}

export default App;
